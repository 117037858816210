.col-4 {
    width: 25%;
    float: left;
}
.col-12 {
    width: 75%;
    float: left;
}

.contact {
    width: 80%;
    min-width: 300px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    margin: 10px 20px 10px 20px;
}
.contact .box p {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 5px 0 5px 0;
}
.contact .box img {
    width: 24px;
}

@media (min-width: 520px) {
    .contact {
        width: 440px;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 20px;
        display: inline-block;
        text-align: center;
        margin: 10px 20px 10px 20px;
    }
    .contact .box p {
        font-size: 22px;
        font-weight: bold;
        margin: 0;
        padding: 5px 0 5px 0;
    }
    .contact .box img {
        width: auto;
    }
}

.contact .box {
    padding: 10px;
    border-right: solid 1px #80AEA4;
}
.contact .col-12:last-child .box {
    border-right-color: transparent;
}