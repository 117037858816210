@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

body {
    color: #005C49;
    font-family: "Roboto Condensed", sans-serif;
    margin: 0;
    background-color: #ADD8EC;
    background: url(../public/images/background.jpg) top center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

a {
    color: #005C49;
    text-decoration: none;;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
}
a:hover { 
    color: #70A498;
}

.App {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0.7) 50%,
        rgba(255, 255, 255, 0.6) 100%
    );
}

.container {
    width: 100%;
    margin: 15% auto;
}

.component {
    width: 100%;
    margin: 0 0 50px 0;
}